import React from 'react'
import {graphql} from 'gatsby'
import Menu from '../components/menu/menu';
import ContourHeader from "../components/contour-header/contour-header";


const About = ({data: {about}}) => (
    <>
        <div className="content">
            <Menu leftTitle="projekte"/>
            <ContourHeader
                text="In meiner Freizeit bin ich am liebsten in den Bergen unterwegs und geniesse eine Wanderung oder eine Skitour im"
                destination="Wallis"
                ort="wallis"
                arrowText="Zu meinem Lebenslauf"
                arrowColor="#D00A1E"
                arrowRef="https://cv-severinkilchhofer.netlify.app/"/>
        </div>
    </>
);

export default About

export const query = graphql`
    query AboutQuery {
        about: datoCmsAboutPage {
            seoMetaTags {
                ...GatsbyDatoCmsSeoMetaTags
            }
            title
            intro
            photo {
                fluid(maxWidth: 600, imgixParams: { fm: "jpg", auto: "compress" }) {
                    ...GatsbyDatoCmsSizes
                }
            }
            bioNode {
                childMarkdownRemark {
                    html
                }
            }
        }
    }
`
